.buttonish {
  @extend .bluish;
  @extend .noselect;
  border: 1px solid transparent; // placeholder for focus
  border-radius: 6px;
  background-color: $ulverbite-green;
  padding: 9px 15px;
  padding-right: 25px;
  min-height: 40px;
  font-weight: 600;
  font-size: $font-size-bigger;
  cursor: pointer;
  color: white;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  outline: none;

  &.text-only {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.secondary {
    background-color: $ulverbite-another-gray;

    &:hover:not(:disabled) {
      background-color: $ulverbite-blue-gray;
    }
  }

  &.white {
    background-color: $ulverbite-white;
    border-color: $ulverbite-dark-gray;

    &:hover:not(:disabled) {
      background-color: $ulverbite-light-gray;
    }
  }

  &.square {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 12px;

    > svg {
      margin: 0;
    }
  }

  &:disabled {
    background-color: $ulverbite-dark-gray;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background: $ulverbite-dark-green;
    color: white;
  }

  > svg {
    @extend .inline-icon;
    margin-right: 15px;
    position: relative;
    top: -2px;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .buttonish {
    width: 100%; // On mobile we want buttons to take the entire width of the screen ...

    &.square {
      width: auto; // ... except for square buttons :)
    }
  }
}
