.split-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > .control-row {
    width: calc(50% - 20px);

    &.full {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .split-table {
    display: block;

    > .control-row {
      width: 100%;
    }
  }
}
