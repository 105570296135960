.signature-file-upload {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;

  > .button {
    padding: 11px;
    cursor: pointer;

    > svg {
      width: 20px;
      height: 20px;
      fill: $ulverbite-another-gray;
    }

    &:hover > svg {
      fill: $ulverbite-blue-gray;
    }
  }
}

input[type="file"] {
  display: none;
}

.signature-file-preview {
  padding: 0;
  width: 100%;
  height: 100px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    max-height: 80px;
  }
}

.floaty.signature-file-deselect {
  top: 5px;
  right: 5px;
  width: 40px;
}

.signature-file-size-error {
  margin: 10px;
}