.check-box {

  > span.text + .container {
    padding-top: 6px;
  }

  > .container {

      /* Hide the browser's default checkbox */
    > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    > .checkmark {
      padding: 3px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      > svg {
        visibility: hidden;
        position: relative;
        top: 1px;
      }
    }

    > input:checked ~ .checkmark > svg {
      visibility: visible;
    }

    > input:focus ~ .checkmark {
      box-shadow: 0 0 0 1px #2684FF;
      border-color: #2684FF;
    }

    > .label {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;

      &.with-description {
        font-size: $font-size-small;
  
        > .description {
          color: $ulverbite-pastel-gray;
          font-size: $font-size-smallest;
          margin-top: 4px;
          margin-left: 1px;
        }
      }
    }

  }

  &.disabled > .container {
    > .checkmark {
      cursor: auto;
      border-color: $ulverbite-dark-gray;
    }

    > .label {
      color: $ulverbite-pastel-gray;

      &.with-description {
        > .description {
          color: $ulverbite-pastel-gray;
        }
      }
    }
  }
  
}

.inline-checkbox {
  > .container {
    display: flex;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) .check-box {
    
    // Hide spacing text on mobile
    > span.text {
      display: none;
    }

    // We don't want any padding as the span.text is hidden on mobile
    > span.text + .container {
      padding: 0;
    }
  }
}
