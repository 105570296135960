.control-row {
  display: block;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }
  
  &:last-child {
    margin-bottom: 0;
  }

  span.text {
    @extend .gray-uppercase;
    display: inline-block;
    width: 50%;
    text-align: right;
    box-sizing: border-box;
    padding: 0 20px;
    vertical-align: middle;
  }

  > div.input-holder {
    display: inline-block;
    width: 50%;    
    box-sizing: border-box;

    input, textarea {
      width: 100%;
    }

    > div.flex-wrapper {
      position: relative;  
      display: flex;
      align-items: center;
      
      > .control-info {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
      }  
    }
  }

  > div.error {
    margin-top: 10px;
    padding-left: 50%;
    font-size: $font-size-small;
    margin-bottom: 20px;
  }

  &.full-line {
    margin-top: 20px;
    margin-bottom: 0;

    &.no-top-margin {
      margin-top: 0;
    }

    span.text {
      @extend .gray-uppercase;
      display: block;
      width: auto;
      text-align: left;
      padding: 0;
      margin-left: 10px;
      margin-bottom: 8px;
    }

    div.input-holder {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    // On mobile we want control row to have the same styling as .full-line class would be applied
    .control-row {
      margin-top: 20px;
      margin-bottom: 0;

      &:first-child {
        margin-top: 0;
      }
  
      &.no-top-margin {
        margin-top: 0;
      }

      span.text {
        font-size: 11px;
        color: $ulverbite-pastel-gray;
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        width: auto;
        text-align: left;
        padding: 0;
        margin-left: 10px;
        margin-bottom: 8px;
      }
  
      div.input-holder {
        width: 100%;
      }

      > .select-box-control {
        width: 100%;
      }
    }
  }
}
