.button-bottom-bar {

  &:not(:empty) {
    margin-top: 20px;
  }

  &.r-align > .buttonish {
    margin-left: 20px;
  }
  
  &.r-align > .action {
    margin-left: 20px;

    &.l-float {
      line-height: 40px;
      margin-left: 0;
      margin-right: 20px;
    }
  }

  &.l-align > .buttonish {
    margin-right: 20px;
  }
  
  &.l-align > .action {
    margin-right: 20px;
  }

  > .pipe {
    color: $ulverbite-pastel-gray;
    line-height: 40px;
    margin-left: -10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .button-bottom-bar {  
      // On mobile we want all action items aligned to center without any margin and float
      &.r-align, &.l-align {
        text-align: center;
        
        > .action.l-float, > .action.r-float {
          float: none;
          margin: 0;
          display: block;
        }
  
        > .buttonish {
          margin: 15px 0 0  0;

          &:first-child {
            margin: 0;
          }
        }
      }

      &.error {
        text-align: center;
      }
    }
  }
}
