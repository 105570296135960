.partner-text-box {
  margin-bottom: 40px;

  > .details {
    position: relative;

    > ul {
      > li:not(:empty) {
        padding: 3px 0;
      }
    }

    > .floaty {
      top: -40px;
    }

    > .remove-button {
      right: -9px;
    }

    > .edit-button {
      right: 30px;
    }
  }

  > div.error {
    margin-top: 10px;
  }

}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .partner-text-box {
      margin-bottom: 20px;
    }
  }
}
