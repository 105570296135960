@import "colors.scss";
@import "fonts.scss";
@import "styles/navigation.scss";
@import "controls/all.scss";
@import "pages/all.scss";

body {
  background: $ulverbite-lightest-gray;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.inline-icon {
  display: inline-block;
  vertical-align: middle;
}

.page {  
  position: relative;
  margin-left: 60px;
  padding: 0 40px;
  min-width: 820px;

  > h1 {
    margin-top: 0;
  }
}

.content {
  max-width: 320px;
  margin: 0 auto;
  padding-top: 20px;
}

.gray-uppercase {
  font-size: 11px;
  color: $ulverbite-pastel-gray;
  text-transform: uppercase;
  font-weight: 600;
}

.panel {
  margin-bottom: 40px;
}

.dummy-top {
  height: 34px;
}

.ellipsiswrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bluish:focus {
  outline: none;
  box-shadow: 0 0 0 1px #2684FF;
  border-color: #2684FF;
}

.clear-float {
  clear: both;
}

.clear-l-float {
  clear: left;
}

.clear-r-float {
  clear: right;
}

.l-float {
  float: left;
}

.r-float {
  float: right;
}

.r-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.hidden {
  display: none;
}

.locked {
  pointer-events: none;
}

.floaty {
  position: absolute;
  width: 32px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  color: $ulverbite-light-black;
  border-radius: 6px;

  > svg {
    position: relative;
    top: 2px;
    width: 14px;
    height: 14px;

    path {
      fill: $ulverbite-light-black;
    }
  }

  &:hover {
    background: $ulverbite-dark-gray;
  }
}

.invoice-attachment-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.invoice-attachment {
  border-radius: 6px;
  padding: 0 15px;
  font-size: $font-size-small;
  line-height: 40px;
  background: $ulverbite-light-gray;
  color: $ulverbite-light-black;
  cursor: pointer;
  @extend .ellipsiswrap;
  max-width: 200px;

  > svg {
    @extend .inline-icon;
    margin-right: 11px;
    position: relative;
    top: -1px;

    path {
      fill: $ulverbite-light-black;
    }
  }

  &.error {
    background: $ulverbite-light-red;
    color: $ulverbite-red;
    cursor: default;

    &:hover {
      color: $ulverbite-red;
    }

    > svg path {
      fill: $ulverbite-red;
    }
    
    > button.remove {
      color: $ulverbite-red;
    }
  }

  &.with-remove-button {
    position: relative;
    padding-right: 40px;

    > button.remove {
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 40px;
      text-align: center;
      background: none;
      cursor: pointer;
    }
  }

  &.uploading {
    cursor: default;
    position: relative;

    > .upload-indicator {
      opacity: 1;
    }
  }

  &:hover {
    color: $ulverbite-light-black;
  }
  
  > .upload-indicator {
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background: $ulverbite-green;
    transition: width 0.1s ease-in-out, opacity 0.2s ease-in-out;
  }
}

.upload-attachment {
  line-height: 40px;
  margin-left: 10px;
  display: inline-block;
  @extend .ellipsiswrap;
}

.bold {
  font-weight: 600;
}

.fav-star {
  margin-right: 5px;
  display: inline;
  vertical-align: baseline;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.emoji {
  display: inline-block;
  vertical-align: baseline;
  width: 23px;
  margin: 0 6px;
  position: relative;
  top: 2px;

  &.top3 {
    top: 3px;
  }

  &.top4 {
    top: 4px;
  }

  &.top5 {
    top: 5px;
  }

  &.top6 {
    top: 6px;
  }

  &.top7 {
    top: 7px;
  }
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.inline {
  display: inline;
}

.show-only-on-mobile {
  display: none;
}

.transparent {
  opacity: 0;
}

.disclaimer-text {
  display: flex;
  margin-top: 20px;
  align-items: flex-start;

  > span {
    margin-top: 5px;
    line-height: 1.5;
  }
}

.text-box-appendix {
  padding-left: 8px;
}

.modal-error-message {
  padding: 10px 0;
}

.after-table-buttons {
  margin: 10px 0;
}

.badge {
  padding: 5px 10px;
  font-size: $font-size-small;
  border-radius: 20px;
  border: 1px solid $ulverbite-light-gray;
  background-color: $ulverbite-white;
  
  &.red {
    background-color: $ulverbite-light-red;
    color: $ulverbite-red;
    border-color: $ulverbite-red;
  }
  
  &.green {
    background-color: $ulverbite-light-green;
    color: $ulverbite-darkest-green;
    border-color: $ulverbite-green;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .hide-on-mobile {
      display: none;
    }

    .show-only-on-mobile {
      display: block;

      &.inline {
        display: inline;
      }
    }

    .content {
      max-width: none;
      padding-top: 0;
    }

    .panel {
      margin-bottom: 20px;
    }

    .page {
      margin-left: 0;
      padding: 0 20px;
      position: static;
      min-width: 0;
    }

    // On mobile we want to position filter inside the navigation header.
    .buttonish.create.square.filter-button {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
      margin: 0;
      width: auto;
      height: auto;
      padding: 12px 32px;
    }

    // On mobile we want invoice attachments to take entire width of the screen.
    .invoice-attachment {
      max-width: none;
      width: 100%;
    }
  }
}
