div.list {
  margin-top: -10px;
  
  > .row {    
    display: flex;
    align-items: baseline;
    
    &.header {
      @extend .gray-uppercase;
    }

    &.header > .col {
      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      > span.text-list-item {
        display: inline-block;
        line-height: 40px;
  
        > svg {
          margin-left: 7px;
          position: relative;
          top: 2px;
        }

        &.clickable {
          cursor: pointer;
        }

        &.selected {
          color: $ucla-blue;
          
          // Ascending direction
          &.direction0 > svg path:first-child {
            fill: $ucla-blue;
          }
          
          // Descending direction
          &.direction1 > svg path:last-child {
            fill: $ucla-blue;
          }
        }
      }
    }

    &.data {
      color: $ulverbite-light-black;
      background: white;
      border-radius: 12px;
      padding: 9px 16px;
      height: 30px;
      line-height: 30px;
      outline: none;
      border: 1px solid transparent;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    
      &:not(.readonly) {
        cursor: pointer;
        @extend .bluish;
      }
    }

    .col {      
      @extend .ellipsiswrap;
      margin-right: 20px;
      box-sizing: border-box;
      flex: 0.4;
    }

    .col.main {
      flex: 0.8;
    }
    
    .col.short {
      flex: 0.2;
    }

    .col.visible-overflow {
      overflow: visible;
    }
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    div.list {
      margin-top: 0;

      > .row.data {
        display: block;
        height: auto;
        line-height: normal;

        .col {
          margin-right: 0;
          font-weight: 600;

          > span.header {
            font-weight: normal;
            color: $ulverbite-pastel-gray;
          }
        }
      }
    }
  }
}
