$hamburger-slice-height: 2px;
$hamburger-first-line: calc(33% / 2 - $hamburger-slice-height / 2);
$hamburger-second-line: calc(33% / 2 + 33% - $hamburger-slice-height / 2);
$hamburger-third-line: calc(33% / 2 + 66% - $hamburger-slice-height / 2);

.hamburger {
  position: relative;
  width: 100%;
  height: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  > span {
    display: block;
    position: absolute;
    height: $hamburger-slice-height;
    width: 100%;
    background: $ulverbite-black;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    
    &:nth-child(1) {
      top: $hamburger-first-line;
    }

    &:nth-child(2), &:nth-child(3) {
      top: $hamburger-second-line;
    }

    &:nth-child(4) {
      top: $hamburger-third-line;
    }
  }

  &.open {
    > span:nth-child(1) {
      top: $hamburger-second-line;
      width: 0%;
      left: 50%;
    }

    > span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    > span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    > span:nth-child(4) {
      top: $hamburger-second-line;
      width: 0%;
      left: 50%;
    }
  }
}
