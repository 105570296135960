// .page.organization {
// }

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .page.organization {
      .control-row {
        margin-top: 20px !important;
      }
    }
  }
}
