.documents-filter-bar {
  margin-bottom: 40px;

  > h2 {
    margin-top: 2px;
  }

  > .split-table {
    > .control-row.full-line {
      margin-top: 0;
      width: calc(33% - 20px);
    }
  }

  > .active-description {
    margin-top: 20px;
    padding: 0 10px;

    > .link {
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .documents-filter-bar {
      margin-bottom: 30px;

      > .split-table {
        > .control-row.full-line {
          width: auto; // On mobile we want control row to take entire width of the screen.

          &:not(:first-child) {
            margin-top: 20px; // Since they occupy entire width of the screen we want also some vertical spacing between multiple filter controls.
          }
        }
      }
      
      > .active-description {
        margin-top: 20px;
        padding: 0;
        text-align: center;
        line-height: 1.5;

        > img {
          display: block;
          margin: 0 auto;
          margin-bottom: 15px;
        }

        > .link {
          margin-left: 0;
          display: block;
        }
      }
    }
  }
}