.menu-dropdown-wrap {
  position: relative;
  max-width: 100px;

  > .mini-dropdown-box {
    padding-left: 10px;
    border-radius: 6px;
    font-size: $font-size-small;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $ulverbite-white;
    display: flex;
    justify-content: space-between;
    color: $ulverbite-light-black;
    cursor: pointer;
  
    &.menu-opened {
    }
  
    &.readonly {
      padding-right: 10px;
      cursor: default;
    }
  
    path {
      fill: $ulverbite-light-black;
    }
  
    > div.dropdown-icon-placeholder {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      padding-left: 8px;
      padding-right: 10px;
    }
  
    &.red {
      background-color: $ulverbite-light-red;
      color: $ulverbite-red;
  
      path {
        fill: $ulverbite-red;
      }
    }
  
    &.green {
      background-color: $ulverbite-light-green;
      color: $ulverbite-darkest-green;
  
      path {
        fill: $ulverbite-darkest-green;
      }
    }
  }
  
  > .dropdown-list.mini-dropdown-menu {
    font-size: $font-size-small;
    right: 0;
    margin-top: 5px;
  }  
}
