div.pagination {
  margin-top: 20px;

  > button {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 9px;
    padding: 0;

    &.r-float {
      margin-right: 0;
      margin-left: 10px;
    }

    > svg {
      margin-right: 0;
      position: relative;
      top: -1px;
      left: 0px;  
      width: 12px;
      height: 10px;
    }
  }
}

.flip-h {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    div.pagination {    
      > button {
        width: 40px;
      }
    }
  }
}