.empty-view {
  margin-top: 80px;
  text-align: center;
  color: $ulverbite-light-black;

  > h2 {
    margin-top: 32px;
    margin-bottom: 40px;
  }

  > .buttonish.create {
    margin: 0;
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .empty-view {
      margin-top: 60px;

      .buttonish {
        display: none;
      }
    }
  }
}
