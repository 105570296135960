$tile-width: 60px;
$tile-height: 60px;

nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $tile-width;
  border-right: 1px solid $ulverbite-dark-gray;
  background: $ulverbite-lightest-gray;
  z-index: 1;

  ol {
    position: fixed;
    
    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    > li {
      display: block;

      > .nav-item {
        text-decoration: none;
        text-align: center;
        display: block;
        width: $tile-width;
        color: $ulverbite-blue-gray;
        cursor: pointer;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        padding-top: 11px;
        padding-bottom: 8px;
        outline: none;
        box-sizing: border-box;

        > div.icon {
          height: 25px;

          > svg > path {
            fill: $ulverbite-black;
          }
        }

        > div.title {
          font-size: 10px;
          margin-top: 2px;
        }

        &.hidden {
          display: none;
        }

        &.logo {
          padding: 36px 0;

          > svg {
            width: 30px;
            height: 30px;
            fill: $ulverbite-black;
          }
        }

        &.selected {
          border-left-color: $ulverbite-green;
          color: $ulverbite-black;
          background: $ulverbite-light-gray;

          > .icon > svg > path {
            fill: $ulverbite-green;
          }
        }

        &:hover:not(.selected):not(.logo) {
          background: $ulverbite-light-gray;
          color: $ulverbite-black;
        }
      }
    }
  }
}

.mobile-header {
  display: none;
  justify-content: space-between;
  border-bottom: 1px solid $ulverbite-dark-gray;
  background: $ulverbite-lightest-gray;

  > .menu-element {
    min-width: 30px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    > h2 {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 480px) {
  body:not(.force-desktop) {
    .mobile-header {
      display: flex;
      align-items: center;
    }

    nav {      
      width: auto;
      border-right: none;
      right: 0;
      top: 44px;

      ol {
        position: static;

        > li > .nav-item {
          width: auto;
          display: flex;
          padding: 20px 23px;
          display: flex;
          align-items: center;

          > div.icon {
            height: auto;
          }

          > div.title {            
            font-size: $font-size-small;
            margin-top: -3px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
